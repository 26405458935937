import './style.scss';

export default !function () {
  const forms = document.querySelectorAll('.get-consultation__form')

  forms.forEach((form) => {
    form.addEventListener('form-sended', (evt) => {
      const { formData } = evt.sendingResult
      const requestId = formData.get('request_id')

      const blockName = form.closest('[data-block-name]')?.dataset.blockName || 'Блок без названия'

     window.$analytics?.push('consult-form-send', {
        requestId,
        blockName
      })
    })
  })
}()
